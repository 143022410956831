// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$red: #CC0000;

$gris-osc: #333;
$gris-med: #666666;
