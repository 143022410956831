//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body{
  font-family:  'Fira Sans', sans-serif ;
  background-color: $white;
}

.landing-right {
  padding: 2rem 0;
}

.bg-gris{
  background-color: #e9ecef;
}

.btn.btn-secondary{
  border-radius: 20px;
  background-color: $red;
  border:none;
  }

.bg-primary {
    background-color: $red !important;
}

.text-primary {
  color: $red !important;
}

.avatar{
  border: 2px solid $red;
}

h1.h2{
  font-weight: 600;
  color: $red;
}


.sidemenu-profile-picture {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: white;
}

.section-title {
  padding-left: 10px;
  left: -4px;
  position: absolute;
  border-width: 7px !important;
  border-color: $red!important;
}

.btn-primary{
  color: #666666;
}

.btn-primary:hover,
.btn-primary:active{
  background-color: $gris-med;
  color: #fff;
  border:none;
}

.navbar-brand {
  padding-top: 0rem;
  padding-bottom: 0.5rem;}

.navbar-nav .nav-item a{
  color: $gris-med;
  font-weight: 800;
}

.navbar-nav .nav-item a:hover,
.navbar-nav .nav-item a.active{
  color: $red;
}

.dropdown-item.active{
  color: $red !important;
  font-weight: 800;
  background-color: transparent !important;}

  .dropdown-item.active > *, .dropdown-item.active > .text-muted, .dropdown-item:active > *, .dropdown-item:active > .text-muted {
    color: $red !important;
  font-weight: 800;
}

.list-inline > .list-inline-item:not(:first-child) a{
    margin-top: 10px;
}


.avatar-club-rn{
  margin-left: 4px !important;
  border: none !important;
}


input[type="radio"]{
  border: 2px solid $red;
}

.nav-tabs .nav-item .nav-link.active {
  border-color: $red;
}

.nav-tabs .nav-item .nav-link {
  border-bottom: 4px solid rgba(138, 21, 21, 0);
  font-size: 20px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $gris-osc;
  font-weight: 800;
}

.text-large{
  font-weight: 600;
  color: $gris-osc;
  font-size: 18px;
  margin-bottom: 14px;
  display: block;
}

.text-large-xl{
  font-weight: 600;
  color: $gris-osc;
  font-size: 24px;
  margin-bottom: 14px;
  display: block;
}


.h3.inicio-registro{
  font-size: 55px;
  line-height: 57px;
  color: #fff;
  text-shadow: 2px 2px 7px $gris-osc;
  padding-top: 27%;
  position: relative;
  padding-bottom:  27%;
  z-index: 1;
}

.h3.mb-5 span{
  font-weight: 600;
}



.form-control{
  border: none;
  border-bottom: 1px solid #ced4da;
}

.reg-g{
  color: $gris-med;
  font-size: 20px;
  font-weight: normal;
}

a.reg-r {
  text-decoration: underline;
  color: $red;
  font-size: 20px;
  font-weight: 600;
}

.reg-r{
  color: $red;
  font-size: 20px;
  font-weight: 600;
}
.reg-g small a{ color: $gris-med;}


.card-title{
  color: $gris-med;
}

.card{
  border-radius: 9px;
}



.alert-info {
  color: $gris-osc;
  background-color: rgba(206, 0, 25, 0.2);
  border-color: $red;
}

@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
  }
}


@media (max-width: 987px){
  .h3.mb-5 {
    font-size: 42px;
    line-height: 43px;
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .ml-5{
      margin-left: 1rem !important;}
}

@media (max-width: 600px){
    .h3.mb-5 {
      margin-bottom: 6% !important;
      padding-top: 5%;
      padding-bottom:  5%;
    }

    .ml-5{
        margin-left: 1rem !important;}
}

// Landing page customizations
.landing-left {

  background-size: cover;
  height: 100vh;
  background-position: top;

  @include media-breakpoint-down(sm) {
    min-height: 100vh;
    height: auto;
  }

  &.home {
    background-image: url(../img/bck-rn.jpg);
  }

  &.club {
    background-image: url(../img/pantalla-vincular-club.jpg);
  }

}

.landing-right {

  @include media-breakpoint-up(sm) {
    height: 100vh;
    overflow: scroll;
  }

  @include media-breakpoint-down(sm) {

    .nav-tabs .nav-item .nav-link {
      font-size: $font-size-sm;
    }
  }

  .csmb-flat {
    width: 16px;
    height: 16px;
    background-size: auto 16px !important;
    vertical-align: middle;
  }
}

.csmb-rionegro {
  background: url(../images/favicon96.png);
}
.csmb-club-rionegro {
  background: url(../img/avatar-club-rn.png);
}
.csmb-googleplus {
  background: url(../images/google.png);
}

.ml1-5{
  margin-left: 1.5rem !important;
}

.div-gender{
  float:left;
  padding:0
}

.float{
  background-color: #ce0019;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 30px !important;
  height: 56px !important;
  width: 56px !important;
  bottom: 20px !important;
  cursor: pointer;
  right: 20px !important;
  position: fixed !important;
  z-index: 1;
  padding: 4px 10px !important;
  transition: all 0.4s !important;
  
  i{
    color: white;
    font-size: xx-large;
    position: absolute;
    right: 13px;
  }

  span{
    color: white;
    margin: 13px !important;
    width: 250px !important;
    visibility: hidden;
    opacity: 0;
    position: absolute !important;
    transition: opacity 1s, transform 1s;
    font-size: medium;
    }

  &:hover{
    width: 326px !important;
    span{
      position: absolute !important;
      opacity: 1;
      visibility: visible;
    }
  }
}
.sin-scroll{
  overflow: hidden !important;
}
.sin-scroll-y{
  overflow-y: scroll !important;
}

.logos {
  max-height: 73px;
  margin: .5em;
}

.user-menu {
  margin-left: .5rem;
  width: 3rem;
}

.welcome{
  flex: auto;
}

.btn.btn-tertiary {
  color: #333;
  background-color: white;
  border: 1px solid #ce141d;  
}

.nav-bar-border {
  border-bottom: 2px solid #ce141d !important;
} 
  
.conocerte-bg {    
  background-image: url(../img/bck-conocerte.jpg);
  background-size: cover;  
  background-position: center;
  
  div {
    background-color: white;
    border-radius: 25px;
    
    h2, .h2{
      font-size: 1.1rem !important;
      line-height: 2rem;
    }
  }
}